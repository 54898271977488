import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DynamicDataEntryComponentOptions } from '../models/dynamic-data-entry-component-options';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { InstanceDataValue } from '../interfaces/instance-data';

@Injectable({
  providedIn: null,
})
export class ComponentService {
  public componentOptions: BehaviorSubject<DynamicDataEntryComponentOptions> = new BehaviorSubject<DynamicDataEntryComponentOptions>(null);
  private component: BehaviorSubject<DynamicDataEntryComponentInterface> = new BehaviorSubject<DynamicDataEntryComponentInterface>(null);

  constructor() {}

  setComponent(component: DynamicDataEntryComponentInterface) {
    this.component.next(component);
  }

  getComponent(): DynamicDataEntryComponentInterface {
    return this.component.getValue();
  }

  // for Kiid-like data format
  public isInstanceDataValueInterface(data: unknown): boolean {
    return (typeof data === 'object' && 'value' in data) || Array.isArray(data);
  }

  public componentIncomingDataAdapter(data: unknown): InstanceDataValue | Partial<InstanceDataValue> {
    if (this.isInstanceDataValueInterface(data)) {
      return data as InstanceDataValue;
    } else {
      return {
        value: data as string,
      };
    }
  }

  public componentOutcomingDataAdapter(data: any, currentState: any): any {
    if (!this.isInstanceDataValueInterface(currentState)) {
      return data;
    } else {
      return Object.assign(currentState, { value: data as string, confirmed: 'Checked', altered: true });
    }
  }
}
