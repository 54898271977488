import { Injectable, Injector } from '@angular/core';
import { TaskDataEntryState } from '../state-management/states';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Task } from '../models/Task';
import { DynamicApiService } from './dynamic-api.service';
import { map } from 'rxjs/operators';
import { UpdateDocuments } from '../state-management/actions';
import { Instance } from '../interfaces/instance-data';
import { InstanceDocument } from '../interfaces/fields';

@Injectable({
  providedIn: null,
})
export class DocumentsLazyLoadingService {
  constructor(private injector: Injector, private apiService: DynamicApiService) {}

  private store = this.injector.get(Store);

  public loadInitialBatch(instances$: Observable<Instance[]>, taskInstances: Observable<Task>): Observable<{ [id: number]: InstanceDocument }> {
    const instancesToGet$ = instances$.pipe(
      map((instances: Instance[]) => {
        const batchSize = this.store.selectSnapshot(TaskDataEntryState.selectDocumentsBatchSize);
        return instances.slice(0, batchSize);
      })
    );
    return this.apiService.getInstanceDocumentsforInstances(instancesToGet$, taskInstances);
  }

  public loadNextBatch(id: number) {
    const batchSize = this.store.selectSnapshot(TaskDataEntryState.selectDocumentsBatchSize);
    const instances = this.store.selectSnapshot(TaskDataEntryState.selectAllInstances());
    const documents = this.store.selectSnapshot(TaskDataEntryState.selectAllDocuments());
    const task$ = this.store.select(TaskDataEntryState.selectTaskInstances);
    const documentsKeys = Object.keys(documents);
    const currentInstance = instances.find((instance) => instance.instance_id === id);
    const currentInstanceIndex = instances.indexOf(currentInstance);
    const currentDocId = currentInstance.doc_id;
    if (instances.length > documentsKeys.length && !documentsKeys.includes(currentDocId.toString())) {
      const instancesForDocs$ = this.store.select(TaskDataEntryState.selectAllInstances()).pipe(
        map((instances) => {
          let instancesToGet = instances.filter((instance) => instances.indexOf(instance) >= currentInstanceIndex && !documentsKeys.includes(instance.doc_id.toString()));
          if (instancesToGet.length > batchSize) {
            instancesToGet = instancesToGet.slice(0, batchSize);
          }
          if (!instancesToGet.includes(currentInstance)) {
            instancesToGet.splice(1, 1, currentInstance);
          }
          return instancesToGet;
        })
      );

      this.apiService.getInstanceDocumentsforInstances(instancesForDocs$, task$).subscribe((documents) => {
        this.store.dispatch(new UpdateDocuments(documents));
      });
    }
  }


}
