import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {EntitiesModule} from '../../../entities/entities.module';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DocProcessAnnotationView} from './pages/doc-process-annotation-view/doc-process-annotation-view.component';
import {KiidInstancesHandlerComponent} from './components/kiid-instances-handler/kiid-instances-handler.component';
import {DocProcessTaskView} from '../doc-process-common/pages/doc-process-task/doc-process-task-view.component';
import {NewsInstancesHandlerComponent} from './components/news-instances-handler/news-instances-handler.component';
import {
  TopicClassificationInstancesHandlerComponent
} from './components/topic-classification-instances-handler/topic-classification-instances-handler.component';
import {DocProcessCommonModule} from '../doc-process-common/doc-process-common.module';
import {
  KiidPriipInstancesHandlerComponent
} from './components/kiid-priip-instances-handler/kiid-priip-instances-handler.component';
import {
  KiidMetadataInstancesHandlerComponent
} from './components/kiid-metadata-instances-handler/kiid-metadata-instances-handler.component';
import {
  KiidFactsheetInstancesHandlerComponent
} from './components/kiid-factsheet-instances-handler/kiid-factsheet-instances-handler.component';
import { FactsheetInputTableComponent } from './components/factsheet-input-table/factsheet-input-table.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { PdfViewerWrapperComponent } from './components/pdf-viewer-wrapper/pdf-viewer-wrapper.component';
import { MultipleChoiceDataEntryComponent } from './components/multiple-choice-data-entry/multiple-choice-data-entry.component';
import { CalenderEventsWidgetComponent } from './components/calender-events-widget.component';
import { NewEventFormComponent } from './components/new-event-form.component';
import { CompanyEventsTableComponent } from './components/company-events-table.component';
import { TopicClassificationTaxonomyComponent } from './components/topic-classification-taxonomy.component';
import {UniversalDataEntryModule} from '../universal-data-entry/universal-data-entry.module';

//TODO: remove when dynamic kiid and factsheet are done
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        EntitiesModule,
        RouterModule,
        NgbModule,
        FormsModule,
        DocProcessCommonModule,
        PdfViewerModule,
        UniversalDataEntryModule,
    ],
    providers: [],
    declarations: [
        DocProcessAnnotationView,
        KiidInstancesHandlerComponent,
        KiidPriipInstancesHandlerComponent,
        KiidFactsheetInstancesHandlerComponent,
        KiidMetadataInstancesHandlerComponent,
        DocProcessTaskView,
        NewsInstancesHandlerComponent,
        TopicClassificationInstancesHandlerComponent,
        FactsheetInputTableComponent,
        PdfViewerWrapperComponent,
        MultipleChoiceDataEntryComponent,
        CalenderEventsWidgetComponent,
        NewEventFormComponent,
        CompanyEventsTableComponent,
        TopicClassificationTaxonomyComponent,
    ],
    exports: [
        DocProcessAnnotationView,
        KiidInstancesHandlerComponent,
        KiidPriipInstancesHandlerComponent,
        KiidFactsheetInstancesHandlerComponent,
        KiidMetadataInstancesHandlerComponent,
        EntitiesModule,
        DocProcessTaskView,
    ]
})
export class KpiAndKiidModule { }
